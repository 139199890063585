<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="transparent" outlined>
          <v-card-title> Beheer </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-btn width="100%" @click="goToRoutingStepTemplates()">
                  Cellen
                </v-btn>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn width="100%" @click="goToRoutingTypes()"> Types </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  beforeCreate() {
    const job = null;
    this.$store.commit("SET_JOB", {
      job,
    });
  },
  methods: {
    goToRoutingStepTemplates() {
      this.$router.push({ name: "beheer/routingStepTemplates" });
    },
    goToRoutingTypes() {
      this.$router.push({ name: "beheer/routingTypes" });
    },
  },
};
</script>
